import React, { useState, useEffect } from "react";
import Link from "gatsby-link";
import Slider from "react-slick";

import "./briefly.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const sliders = [
	{
		index: 1,
		title: "Электронная транспортная карта",
		content:
			"Транспортная карта дает право проезда по оплаченному тарифу в пределах границ города Казани.",
		link: "/cards/etc"
	},
	{
		index: 2,
		title: "50 поездок",
		content:
			"Эти тарифы с лимитом поездок - 50, и сроком действия - 30 дней с момента пополнения.",
		link: "/services/tariffs"
	},
	{
		index: 3,
		title: "Терминалы AQSI 5Ф",
		content:
			"Терминалы работают в офлайн режиме на базе операционной системы Android. Стабильный интернет с поддержкой технологии передачи данных 4G.",
		link: "/"
	},
	{
		index: 4,
		title: "Годовой   Недельный",
		content:
			"Годовой - стоимость поездки 38 руб.    Недельный - стоимость поездки 39 руб.",
		link: "/services/tariffs"
	},
];

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplaySpeed: 10000,
	autoplay: true,
	lazyLoad: true,
	pauseOnHover: false,
	appendDots: dots => (
		<ul
			style={{
				margin: "0px"
			}}
		>
			{dots}
		</ul>
	),
	customPaging: () => (
		<div className="countdown">
			<div className="countdown-number" />
			<svg>
				<circle r="7" cx="8" cy="8" />
				<circle r="7" cx="8" cy="8" />
			</svg>
		</div>
	)
};

const BrieflySliderItem = props => {
	const { index, title, content, link } = props;
	return (
		<>
			<section className={`slider-item item-${index}`}>
				<div className="grid-container">
					<div className="row">
						<div className="hidden-xs col-sm-6 col-md-6">
							<h1 className="slider-item__header">{title}</h1>
							<p className="slider-item__text">{content}</p>
							<Link to={link} className="slider-item__details">
								Подробнее ⟶
							</Link>
						</div>
					</div>
				</div>
			</section>
			<div className="grid-container hidden-sm hidden-md">
				<div className="row">
					<div className="col-xs-12">
						<h1 className="slider-item__header">{title}</h1>
						<p className="slider-item__text">{content}</p>
						<Link to={link} className="slider-item__details">
							Подробнее ⟶
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

const Briefly = () => {
	const [slider, setSlider] = useState(undefined);
	const [currentSlide, setCurrentSlide] = useState(1);

	useEffect(() => {
		slider && document.addEventListener("visibilitychange", slider.slickNext);
		return () => {
			slider &&
				document.removeEventListener("visibilitychange", slider.slickNext);
		};
	}, [slider]);

	const next = () => {
		slider.slickNext();
	};
	const previous = () => {
		slider.slickPrev();
	};

	const afterChangeHandler = currentSlide => {
		setCurrentSlide(currentSlide + 1);
	};

	const renderCounter = () => {
		return (
			<div className="slider-counter">
				{sliders.length && `${currentSlide}/${sliders.length}`}
			</div>
		);
	};

	const renderArrows = () => {
		return (
			<div className="slider-arrows">
				<button className="button" onClick={previous}>
					Previous
				</button>
				<button className="button" onClick={next}>
					Next
				</button>
			</div>
		);
	};

	return (
		<section className="briefly full-width">
			<div className="grid-container">
				<div className="slider-controls">
					{renderArrows()}
				</div>
			</div>
			<Slider
				ref={c => setSlider(c)}
				afterChange={afterChangeHandler}
				{...settings}
			>
				{sliders.map(slider => (
					<BrieflySliderItem key={slider.index} {...slider} />
				))}
			</Slider>
		</section>
	);
};

export default Briefly;
